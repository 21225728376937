@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper{
  @include fwcontainer;
  @include respond(tablet) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .bannerOuterWrapper{
    background-image: url(../../../assets/images/Frame.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center bottom;
    border-radius: 12px;

    @include respond(tablet) {
      height: 243px;
      background-image: url(../../../assets/images/mwebFrame.svg);
      padding: 12px;
      border-radius: 6px;
    }
    padding: 24px 4px 24px 40px;
    display: flex;
    flex-direction: column;
    .bannerTitle{
      width: 596px;
      white-space: pre-line;
      height: 92px;
      color: #FFDF9A;
      font-family: "Avenir";
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
      .bannerSIQText{
        color: var(--global-colors-yellow-100, #FFF8E9);
        font-family: "Avenir";
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        @include respond(tablet) {
          color: var(--global-colors-yellow-100, #FFF8E9);
          font-family: "Avenir";
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: 150%;
        }
      }
      @include respond(tablet) {
        width: 304px;         
        height: 60px;
        color: #FFDF9A;
        font-family: "Avenir";
        font-size: 20px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 30px */
      }
    }
    .bannerInnerText{
      width: 570px;
      white-space: pre-line;
      margin-top: 4px;
      color: var(--alias-grayscale-input, #EFF0F6);
      font-family: "Avenir";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      @include respond(tablet) {
        width: 281px;
        color: var(--alias-grayscale-input, #EFF0F6);
        font-family: "Avenir";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
    .bannerStartNow{
      margin-top: 8px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.20);
      backdrop-filter: blur(2px);
      display: flex;
      border: 1px solid var(--alias-grayscale-background,rgba(255, 255, 255, 0.20));
      justify-content: center;
      align-items: center;
      width: 136px;
      height: 40px;
      color: var(--alias-grayscale-background, #FCFCFC);
      font-family: "Avenir";
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
        letter-spacing: 0.25px;
      span{
        color: var(--alias-grayscale-background, #FCFCFC);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Avenir";
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 22px;
        letter-spacing: 0.25px;
      }
    }
    .bannerStartNow:hover{
        border: 1px solid var(--alias-grayscale-background, #FCFCFC);
        background: rgba(255, 255, 255, 0.32);
    }
  }
  margin-bottom: 64px;
  @include respond(tablet) {
    margin-bottom: 28px;
  }
}